import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {AgoraWindowAction} from "./types";
import {AgoraChannel, CallType} from "@sense-os/goalie-js";

export const agoraActions = {
	// Open Agora window actions
	openAgoraWindow: createAsyncAction(
		"agora/openAgoraWindow",
		"agora/openAgoraWindowSucceed",
		"agora/openAgoraWindowFailed",
	)<{windowAction: AgoraWindowAction; meetingText?: string; channelId?: string; callType?: CallType}, void, Error>(),

	// Close Agora window action
	closeAgoraWindow: createAction("agora/closeAgoraWindow")(),

	// Reset Agora window state action
	resetAgoraWindowState: createAction("agora/resetAgoraWindowState")(),

	// Open Agora invitation dialog action
	openInvitationDialog: createAction(
		"agora/openInvitationDialog",
		(invitationCreator?: string, invitationLink?: string) => ({invitationCreator, invitationLink}),
	)(),

	// Close Agora invitation dialog action
	closeInvitationDialog: createAction("agora/closeInvitationDialog")(),

	// Agora created meeting action
	userCreatedMeeting: createAction("agora/userCreatedMeeting", (createdLink: string) => ({createdLink}))(),

	// Agora meeting joined time action
	userJoinedMeeting: createAction("agora/userJoinedMeeting", (joinedTime: number) => ({joinedTime}))(),

	// Agora meeting leave time action
	userLeaveMeeting: createAction("agora/userLeaveMeeting", (leaveTime: number) => ({leaveTime}))(),

	// Agora meeting create channel action
	createAgoraChannel: createAsyncAction(
		"agora/createAgoraChannel",
		"agora/createAgoraChannelSucceed",
		"agora/createAgoraChannelFailed",
	)<{channelName?: string}, {agoraChannel: AgoraChannel}, Error>(),

	// Agora meeting get channel action
	getAgoraChannel: createAsyncAction(
		"agora/getAgoraChannel",
		"agora/getAgoraChannelSucceed",
		"agora/getAgoraChannelFailed",
	)<{channelId: string}, {agoraChannel: AgoraChannel}, Error>(),

	setRtcToken: createAction("agora/setRtcToken", (rtcToken: string) => ({rtcToken}))(),
};

export type AgoraActionType = ActionType<typeof agoraActions>;
